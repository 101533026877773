import React from 'react'
import { PageTransition } from '../components/transition'

const NotFoundPage = () => (
  <PageTransition>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </PageTransition>
)

export default NotFoundPage
